import firebase from 'firebase/app';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyDGR-9JwnOVeMtArY-WlsQdR81dtIUGYBY",
    authDomain: "pedidosGloria-1a5fe.firebaseapp.com",
    databaseURL: "https://pedidosGloria-1a5fe.firebaseio.com",
    projectId: "pedidosGloria-1a5fe",
    storageBucket: "pedidosGloria-1a5fe.appspot.com",
    messagingSenderId: "875720123460",
    appId: "1:875720123460:web:222b1abe215620c7610811",
    measurementId: "G-Q3LCKWH0J6"
};

var fire = firebase.initializeApp(firebaseConfig);

firebase.analytics();

export default fire;