import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import axios from 'axios';
import firebase from '../../constants/firebase';
import { withRouter } from "react-router-dom";
import Progress from '../util/Progress';

import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav';
import AlertModal from '../modal/AlertModal';
import CheckOut from '../checkout/CheckOut';
import Footer from '../footer/Footer';
import Search from '../util/Search';

import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';

import minus from '../../assets/icon/minus.svg';
import plus from '../../assets/icon/plus.svg';
import error from '../../assets/icon/error.svg';
import noimage from '../../assets/img/logo_login.png';
import eliminar from '../../assets/icon/eliminar.svg';

const Carrito = (props) => {

    const style = {
        'text-align': 'center',
        'padding-top': '130px',
        'font-size': '1.5rem'
    }

    const [search, setSearch] = useState('');
    const [alerta, setAlerta] = useState();
    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [ico, setIco] = useState(0);
    const [checkOut, setCheckOut] = useState(false);
    const [loader, setLoader] = useState(false);
    const [enviado, setEnviado] = useState(false)
    const [message, setMessage] = useState();
    const [minPedido, setMinPedido] = useState(0);
    const [maxPedido, setMaxPedido] = useState(140000);


    const handleSearch = value => {
        props.history.push("/catalogo/packs-15?q=" + value);
    }

    const getIva = () => {
        let listCarrito = Auth.getCarrito();
        let iva = 0;
        Object.keys(listCarrito).map((item, i) => {
            let subtotal = listCarrito[item]["cantidad"] * listCarrito[item]["iva"];
            iva += (subtotal);
        })
        setIva(iva);
    }

    const getIco = () => {
        let listCarrito = Auth.getCarrito();
        let ico = 0;
        Object.keys(listCarrito).map((item, i) => {
            let subtotal = listCarrito[item]["cantidad"] * listCarrito[item]["ico"];
            ico += (subtotal);
        })
        setIco(ico);
    }

    const getTotal = () => {
        let listCarrito = Auth.getCarrito();
        let total = 0;
        Object.keys(listCarrito).map((item, i) => {
            total += listCarrito[item]["cantidad"] * listCarrito[item]["precio"];
        })
        setTotal(total);
    }

    // AUMENTA LA CANTTIDAD
    const handleMas = (codigo, item) => {   
        setCheckOut(false);
        var carrito = props.carrito || {};  
        if(carrito[codigo]){
            if(parseInt(carrito[codigo]['cantidad']) < 99)
                carrito[codigo]["cantidad"] += 1;
            else{
                setAlerta({
                    tipo: "error",
                    titulo: "Error.",
                    mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
                    btnOk: "Aceptar",
                    fnOk: function(){},
                    fnCancel: null
                })    
            }
        }else{
            carrito[codigo] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1,
                iva: parseInt(item.iva),
                ico: parseInt(item.ico)
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        getIva();
        getIco();
        getTotal();
    }

    const handleMenos = (codigoSku, item)  => {
        setCheckOut(false);
        var carrito = props.carrito || {};  
        if(carrito[codigoSku]){
            if(carrito[codigoSku]["cantidad"] == 1){
                delete carrito[codigoSku];
            }else{
                carrito[codigoSku]["cantidad"] -= 1;
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        getIva();
        getIco();
        getTotal();
    }

    const handleChangeCantidad = (codigoSku, item, valor) => {
        setCheckOut(false);
        var carrito = props.carrito || {};  
        if(valor <= 0){
            delete carrito[codigoSku];
        }else{
            if(carrito[codigoSku]){
                if(parseInt(valor) < 100)
                    carrito[codigoSku]["cantidad"] = parseInt(valor);
                else{
                    carrito[codigoSku]["cantidad"] = carrito[codigoSku]["cantidad"];
                    setAlerta({
                        tipo: "error",
                        titulo: "Error.",
                        mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
                        btnOk: "Aceptar",
                        fnOk: function(){},
                        fnCancel: null
                    })    
                }
            }else{
                carrito[codigoSku] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: parseInt(item.iva),
                    ico: parseInt(item.ico)
                }
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        getIva();
        getIco();
        getTotal();
    }

    const handleEliminar = (item) =>{
        setCheckOut(false);
        var carrito = props.carrito || {}; 
        if(carrito[item].cantidad > 0) {
            setAlerta({
                tipo: "error",
                icon:  Constants.URL_BASE +'image/' + (item || '' )+ '.png',
                titulo: "Eliminar producto.",
                mensaje: "¿Está seguro de eliminar " + carrito[item].nombre + " ?",
                btnOk: "Si, eliminar",
                btnCancel: "No, cancelar",
                fnOk: function(){
                    carrito[item].cantidad=1;
                    handleMenos(item);
                },
                fnCancel: function(){}
            })       
        }
    }

    const handleCancelarPedido = () =>{
        setCheckOut(false);
        setAlerta({
            tipo: "error",
            titulo: "Cancelar pedido.",
            mensaje: "¿Esta seguro de cancelar el pedido?. Se perderan todos los productos.",
            btnOk: "Si, eliminar",
            btnCancel: "No, cancelar",
            fnOk: function(){
                Auth.removeCarrito();
                props.ActualizarCarrito({});
                getIva();
                getIco();
                getTotal();
            },
            fnCancel: function(){}
        })      
    }

    const handelCheckOut = () => {
        let cliente = props.usuario
        if(cliente){
            setCheckOut(true)
            firebase.analytics().logEvent("checkout", cliente.doc + "-" + cliente.email);
        }else{
            setAlerta({
                tipo: "info",
                titulo: "Inicio de sesión.",
                mensaje: "Por favor inicie sesión o registrese para terminar el pedido",
                btnOk: "Ingresar o Registrarse",
                btnCancel: "Cancelar",
                fnOk: function(){
                    props.history.push('/login');
                },
                fnCancel: function () {}
            })   
        }
    }

    const handlePedido = () => {
        setLoader(true)
        let cliente = props.usuario

        let detalle=[];

        Object.keys(props.carrito).map((item, i) => {
            detalle.push({
                "codigoSku": item,
                "cantidad": props.carrito[item].cantidad
            })
        })

        let pedido = {
            observacion: cliente.observacion ? cliente.observacion : '',
            tipoPago: "CT",
            clienteId: cliente.id,
            plataforma: "WEB",
            fecha: cliente.fecha,
            detalle:detalle
        }

        if(cliente){
            axios.post(Constants.URL_BASE + Constants.WSPEDIDO, pedido, {
                headers:{
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }).then((response)=>{
                console.log(response);
                firebase.analytics().logEvent("finalizar_checkout", cliente.doc + "-" + cliente.email);
                setEnviado(true)
                setLoader(false)
                setTotal(0)
                setIco(0)
                setIva(0)
                setMessage(response.data.message)
                setCheckOut(false)
                Auth.removeCarrito()
                props.ActualizarCarrito({})
                
                setAlerta({
                    tipo: "success",
                    titulo: "Pedido.",
                    mensaje: response.data.message,
                    btnOk: "Historial",
                    btnCancel: "Ir al catalogo",
                    fnOk: function(){
                        props.history.push('/pedidos')
                        cliente.observacion=null
                        props.ActualizarUsuario(cliente)
                    },
                    fnCancel: function(){
                        props.history.push('/')
                        cliente.observacion=null
                        props.ActualizarUsuario(cliente)
                    }
                })   
            }).catch((err)=>{
                console.log(err);
                setLoader(false)
            })
        }

    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    const topePedido = () => {
        var ubicacion = Auth.getUbicacion();
        let url = Constants.URL_BASE + Constants.WSTOPEPEDIDO + "?id_ciudad=" + ubicacion.municipioId;
        axios.get(url)
        .then((response)=>{
            if(response.data!="")
                setMinPedido(response.data.valor);
            else   
                setMinPedido(0)
        }).catch((error)=>{
            setMinPedido(0)
        });
    }

    const validatePedido = () => {
        var ubicacion = Auth.getUbicacion();
        var carrito = Auth.getCarrito();

        if(Object.keys(ubicacion).length > 0){
            let url = Constants.URL_BASE + Constants.WSCATALOGO + 
                    "?DepartamentoId="+ ubicacion.departamentoId +
                    "&MunicipioId=" +  ubicacion.municipioId;
            let detalle=[];
            Object.keys(carrito).map((item, i) => {
                detalle.push({
                    "codigoSku": item,
                    "precio": carrito[item].precio,
                    "iva": carrito[item].iva,
                    "ico": carrito[item].ico
                })
            })

            axios.post(url, {"detalle": detalle})
            .then((response)=>{
                var dataArray = response.data;
                var carritoAux = {};
                dataArray.map((item, i) => {
                    if(carrito[item.codigoSku]){
                        carritoAux[item.codigoSku] = {
                            nombre: carrito[item.codigoSku]['nombre'],
                            precio: item.precio,
                            cantidad: parseInt(carrito[item.codigoSku]['cantidad']),
                            iva: parseInt(item.iva),
                            ico: parseInt(item.ico)
                        }
                    }
                });
            
                Auth.setCarrito(carritoAux);
                props.ActualizarCarrito(Auth.getCarrito());

                getIva();
                getIco();
                getTotal();
            });
        }
    }

    useEffect(() => {
        validatePedido();
        topePedido();
        
    }, [])

    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar principal navcarrito">
                    <Navbar item={2}/>
                    {/* <Search search={search} updateSearch={handleSearch} /> */}
                    <Sub_nav/>

                </div>

                <div className="layout">
                    <div className="mobile">
                        <div className="pedido-list carrito">
                            { loader ? <Progress color={"#1F2A4E"} /> : (
                                <React.Fragment>
                                    {props.carrito  && !enviado? (
                                        <React.Fragment>
                                            {Object.keys(props.carrito).length > 0 ? (
                                                <React.Fragment>
                                                    <h2 className="titulo-carrio">Verifica tu pedido</h2>
                                                    <h4 className="titulo-carrio">Si todo está correcto haz clic en confirmar pedido.</h4>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <h2 className="titulo-carrio">El carrito está vacío</h2>
                                                    <div style={{textAlign:'center'}}>
                                                        <button className="btn-large btn-catalogo" onClick={e => handleRedirect('/')}>Ir al catálogo</button>
                                                    </div>

                                                </React.Fragment>
                                            ) }
                                            
                                            <div className="product-list">
                                                {Object.keys(props.carrito ).map((item, i) => (
                                                    <div className="item item-carrito" key={i}>
                                                        <img 
                                                            className="img-product"
                                                            src={ Constants.URL_BASE +'/image/'+ item + '.png'}
                                                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                                        </img>
                                                        <div className="start">
                                                        
                                                            <p className="nombre">{props.carrito [item].nombre}</p>
                                                            <p className="precio">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn", 
                                                                    style: "decimal",
                                                                    currency: "COP"
                                                                }).format(props.carrito [item].precio)}
                                                                </p>
                                                            <p className="regular">
                                                                Total 
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn", 
                                                                    style: "decimal",
                                                                    currency: "COP"    
                                                                }).format((props.carrito [item].precio) * props.carrito[item].cantidad)} 
                                                            </p>
                                                        </div>
                                                        <div className="end">
                                                            <div className="action">
                                                                <div className="controls">                                            
                                                                    <button role="button" onClick={() => handleMenos(item, props.carrito[item])}>
                                                                        <img src={minus} alt="icon minus" width="15px" height="15px"></img>
                                                                    </button>
                                                                    <input type="number" minLength="0" maxLength="99"
                                                                            onChange={e => handleChangeCantidad(item, props.carrito[item], e.target.value)}
                                                                            value={props.carrito [item].cantidad} />
                                                                    <button role="button" onClick={e => handleMas(item, props.carrito[item])}>
                                                                        <img src={plus} alt="icon plus" width="15px" height="15px"></img>
                                                                    </button>
                                                                </div>
                                                                <a style={{cursor:"pointer"}} onClick={e => handleEliminar(item)}>
                                                                    <img src={eliminar} alt="icon delete" width="30px" height="30px"></img>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ) : 
                                    <React.Fragment>
                                        <div style={style}>
                                            <p >{message ? message : ''}</p>                                
                                        </div>
                                        
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                        </div>


                        <div className="total">
                            <div className="total-content">
                                <div className="item">
                                    <p className="opc">Subtotal:</p>
                                    <p className="val">
                                        {" $" + new Intl.NumberFormat("en", {
                                            numberingSystem: "latn", 
                                            style: "decimal",
                                            currency: "COP"
                                        }).format(total-iva-ico)}
                                    </p>
                                </div>
                                <div className="item">
                                    <p className="opc">Iva:</p>
                                    <p className="val">
                                    {" $" + new Intl.NumberFormat("en", {
                                        numberingSystem: "latn", 
                                        style: "decimal",
                                        currency: "COP"
                                    }).format(iva)}
                                    </p>
                                </div>
                                <div className="item">
                                    <p className="opc">Ico:</p>
                                    <p className="val">
                                    {" $" + new Intl.NumberFormat("en", {
                                        numberingSystem: "latn", 
                                        style: "decimal",
                                        currency: "COP"
                                    }).format(ico)}
                                    </p>
                                </div>
                                {/* <div className="item">
                                    <p className="opc">Envío:</p>
                                    <p className="val">
                                        Gratis
                                    </p>
                                </div> */}
                                <div className="item">
                                    <p className="opc">Total:</p>
                                    <p className="val">
                                    {" $" + new Intl.NumberFormat("en", {
                                        numberingSystem: "latn", 
                                        style: "decimal",
                                        currency: "COP"
                                    }).format((total))}
                                    </p>
                                </div>
                            </div>

                            {/* <p className="titulo-carrio">Si haces tu pedido antes de las 4pm será entregado mañana</p> */}

                            
                            <React.Fragment>
                                                             
                                {!loader && !message? (
                                    <React.Fragment>
                                        {checkOut  ? (
                                            <CheckOut />
                                        ) : (
                                            null
                                        )}
                                        {!loader && total>=minPedido && total<maxPedido ? (
                                            <React.Fragment>
                                                {checkOut ? (
                                                    <button type="button" className="btn-large" onClick={e => handlePedido()}>Confirmar pedido</button>
                                                ) : (
                                                    <button type="submit" className="btn-large" onClick={e => handelCheckOut()}>Confirmar pedido</button>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            null
                                        )}
                                        <button type="button" className="btn-large outline" onClick={ () => handleRedirect('/catalogo/gaseosas-7')}>Agregar más productos</button>
                                        { Object.keys(props.carrito).length > 0 ? (
                                            <button type="button" className="btn-large outline" onClick={ e => handleCancelarPedido()}>Cancelar pedido</button>
                                        ) : null }  
                                    </React.Fragment>
                                ) : (
                                    null
                                )}
                                        
                            </React.Fragment>

                            {total<minPedido ? (
                                <div className="carrito-validacion">
                                    <div>
                                        <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                    </div>
                                    <div className="contenido">
                                        <p style={{color:"red"}}><b>El valor mínimo de envío es de 
                                            {" $" + new Intl.NumberFormat("es-CO", {
                                                style: "decimal",
                                                currency: "COP"
                                            }).format(minPedido)}
                                        </b></p>
                                        <p>Completa tu pedido para que disfrutes de tus productos en casa.</p>

                                    </div>
                                    
                                </div>
                            ) : (
                                <React.Fragment>
                                   
                                    
                                </React.Fragment>
                            )}

                            {total>maxPedido ? (
                                <div className="carrito-validacion">
                                    <div>
                                        <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                    </div>
                                    <div className="contenido">
                                        <p style={{color:"red"}}><b>El Valor Máximo del pedido debe ser
                                            {" $" + new Intl.NumberFormat("es-CO", {
                                                style: "decimal",
                                                currency: "COP"
                                            }).format(maxPedido)}
                                        </b></p>                                       
                                    </div>
                                    
                                </div>
                            ) : (
                                <React.Fragment>
                                   
                                    
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
            <Footer />

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarCarrito, ActualizarUsuario}) (Carrito));