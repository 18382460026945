import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// COMPONENTS
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Marca from '../navbar/Marca'
import Categoria from '../navbar/Categoria'
import Footer from '../footer/Footer'
import CardProducto from '../util/CardProducto'
import AlertModal from '../modal/AlertModal'
import PublicidadModal from '../modal/PublicidadModal'
import Auth from '../../helpers/Auth'
import Search from '../util/Search'

import * as Constants from '../../constants/global';
import axios from 'axios';

// IMAGEN
import descargapp from '../../assets/img/descargapp.png'
import noimage from '../../assets/img/logo_login.png'
import rigth from '../../assets/icon/rigth.svg'

import { Cookies } from 'react-cookie'
const cookies = new Cookies();

const Inicio = (props) => {
    
    const [search, setSearch] = useState('');
    const [alerta, setAlerta] = useState();
    const [categorias, setCategorias] = useState();
    const [popup, setPopup] = useState();
    const [banner, setBanner] = useState();
    const [productos, setProductos] = useState();

    const options = {
        margin: 10,
        responsiveClass: true,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 3,
            },
            400: {
                items: 4,
            },
            600: {
                items: 6,
            },
            700: {
                items: 7,
            },
            1000: {
                items: 11,
    
            }
        },
    };

    const categoriaData = () => {
        let url = Constants.URL_BASE + Constants.WSCATEGORIAS

        axios.get(url)
        .then(res => {
            let listData = res.data || [];
            if(listData.length == 0){
                listData.splice(0, 0, {codigo: -1, descripcion: "TODAS"});
            }
            setCategorias(listData);
        });
    };


    const bannerData = () => {
        let url = Constants.URL_BASE + Constants.WSBANNER
        axios.get(url)
        .then(res => {
            setBanner(res.data);
        });
    };

    const popupData = () => {
        const popCookie = cookies.get("publicidad");
        if(!popCookie){
            let url = Constants.URL_BASE + Constants.WSPOPUP
            axios.get(url)
            .then(res => {
                setPopup(res.data);
            });
        }
       
    };

    const productoHomeData = () => {
        let url = Constants.URL_BASE + Constants.WSPRODUCTOHOME

        var ubicacion = Auth.getUbicacion();

        if(Object.keys(ubicacion).length > 0) {
            url += "?DepartamentoId="+ ubicacion.departamentoId +"&MunicipioId=" +  ubicacion.municipioId;
        }

        axios.get(url)
        .then(res => {
            setProductos(res.data);
        }).catch(err =>{
            console.log(err)
        })
    };

    const onClickItem = (item) => {
        window.location.href = banner[item].enlace;
    }

    const clickCategoria = (item) => {
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/catalogo/" + res);
    }

    const handleCategoria = item => {}

    const handleSearch = value => {
        props.history.push("/catalogo/packs-15?q=" + value);
    }

    useEffect(() => {
        popupData();
        bannerData();
        productoHomeData();
        categoriaData();
    }, [])

    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar">
                    <Navbar item={2}/>
                    <Search search={search} updateSearch={handleSearch} />
                    <Sub_nav/>
                    <Categoria categoriaId={null} handleCategoria={handleCategoria}/>
                </div>

                <div className="layout">
                    <div className="inicio">
                        {banner && (
                            <div className="banner">
                                 <Carousel 
                                    showArrows={true} 
                                    showThumbs={false} 
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    onClickItem={onClickItem} >
                                    {banner.map((item, i) => (
                                
                                        <div> 
                                            <img 
                                                className="img-banner"
                                                src={ Constants.URL_BASE +'banner/'+ item.imagen}
                                                onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                            </img>
                                        </div>
                                    
                                    ))} 
                                 </Carousel>
                            </div>
                        )}

                        {/* {categorias && (
                            <React.Fragment>
                                <div className="inicio-titulo">
                                    <h2>
                                        Categorias 
                                    </h2>
                                    <a href="/catalogo/gaseosas-7">
                                        Ver Catálogo
                                        <img src={rigth} alt="icon left" width="10px" height="10px"></img>
                                    </a> 
                                </div>
                                <div className="inicio-categoria">
                                    <OwlCarousel
                                    className="owl-theme"
                                    {...options}>
                                        { categorias.map((item, i)=>(
                                            <div className="item">
                                                <div onClick={() => clickCategoria(item)}>
                                                    <a>
                                                        {item.nombre}
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </React.Fragment>
                            
                        )} */}
                 
                        {/* <Marca /> */}
                       
                        { productos && (
                            <React.Fragment>
                                { productos.map((item, i) => (
                                    <React.Fragment>
                                         <div className="inicio-titulo">
                                            <h2>{item.nombre}</h2>
                                            <a onClick={() => clickCategoria(item)} >
                                                Ver todo
                                                <img src={rigth} alt="icon left" width="10px" height="10px"></img>
                                            </a> 
                                        </div>
                                        <div className="pedido-list">
                                            <div className="product-card">
                                                { item.producto.map((producto, j) => (
                                                    <CardProducto 
                                                        key={i}
                                                        producto={producto}
                                                        setAlerta={setAlerta}/>
                                                ))}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )) }
                            </React.Fragment>
                        )}

                       
                    </div>
                </div>
            </div>

            {/* <div className="descargaapp">
                <img src={descargapp} />
            </div> */}
            
            <Footer />
            { popup ? (<PublicidadModal popup={popup} />) : null}
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
        </React.Fragment>
    )
}

export default Inicio
